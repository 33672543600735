import {useEffect, useState} from 'react'
import dayjs from 'dayjs'

export default function CalcularSocio(props) {
    const [edad, setEdad] = useState(0)
    const [cate, setCate] = useState('')
    const [alta, setAlta] = useState(0)

    useEffect (() => {
        const hoy = dayjs(new Date())
        const nac = dayjs(props.socio.nacimiento)
        const ed = hoy.diff(nac, 'year')

        const alta_soc = dayjs(props.socio.alta_sistema)
        const ant = hoy.diff(alta_soc, 'year')

        if (ed<=5) setCate('Menor')
        if (ed>5 && ed<18) setCate('Cadete')
        if (ed>18 && ed<60 && props.socio.sexo==='F') setCate('Dama')
        if (ed>18 && ed<60 && props.socio.sexo==='M') setCate('Activo')
        if (ed>=48 && ant>=30) setCate('Vitalicio')
        if (ed>60 && ant <=30) setCate('Jubilado')
        setEdad(ed)
        setAlta(ant)
    })

    return (<>
        <div className="flex flex-row justify-center">
            <img className="max-h-64" src={"https://clubrionegro.ar/back/uploads/" + props.socio.imagen } alt='Imagen Socio'/>
        </div>
        <div className="pb-2 w-3/4 text-align">
            <div className="flex flex-row justify-between mt-2">
                <label className="text-lg">Edad</label>
                <div className="w-1/4 flex flex-row justify-between">
                    <label className="text-lg mr-5">{edad}</label>
                    <label className="text-lg">años</label>
                </div>
            </div>
            <div className="flex flex-row justify-between mt-2">
                <label className="text-lg">Antigüedad</label>
                <div className="w-1/4 flex flex-row justify-between">
                    <label className="text-lg mr-5">{alta}</label>
                    <label className="text-lg">años</label>
                </div>
            </div>
            <div className="flex flex-row justify-between my-2">
                <label className="text-lg">Categoría</label>
                <label className="text-lg">{cate}</label>
            </div>
        </div>
    </>)
}