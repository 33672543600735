import { useState } from 'react'
import BuscarSocio from './buscar_socio_ing.js'
import MostrarIngreso from './mostrar_ingreso.js'
import BuscarAutorizado from './buscar_autorizado_ing.js'

export default function Ingreso(props) {
    const [socio, setSocio] = useState({id: 0})
    const [recargar, setRecargar] = useState(0)
    return (<>
    <div className="flex flex-col w-full">
        <div className="flex flex-row max-h-1/3">
            <div className="max-h-1/3 border-r-2 border-black px-3">
                <p className="text-center text-lg">Socio</p>
                <BuscarSocio back={props.back} token={props.token} setSocio={setSocio} setRecargar={setRecargar} recargar={recargar} actividades={props.actividades}/>
            </div>
            <div className="max-h-1/3 px-3">
                <p className="text-center text-lg">Autorizado</p>
                <BuscarAutorizado back={props.back} token={props.token} setSocio={setSocio} setRecargar={setRecargar} recargar={recargar} actividades={props.actividades}/>
            </div>
        </div>
        <div >
        {socio.id ?
            <MostrarIngreso back={props.back} token={props.token} socio={socio} setSocio={setSocio} actividades={props.actividades}/>
        :
        ''
        }
        </div>
    </div>
    </>)
}