import { useState } from 'react'
import BuscarSocio from './buscar_socio.js'

export default function Socios(props) {
    
    const [recargar, setRecargar] = useState(0)
    return (<>
    <div className="w-1/2 max-h-1/2">
        <div className="p-2">
            <BuscarSocio back={props.back} token={props.token} setSocio={props.setSocio} setRecargar={setRecargar} recargar={recargar}/>
        </div>
    </div>
        
    </>)
}