import {useState, useEffect} from 'react'

export default function Socios(props) {

    function registrar() {
        fetch(props.back + 'admin/registrar_ingreso/id/' + props.socio.id ,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json' 
            }
            })
        .then (response => response.json())
        .then (json => {if(json.data==='Ok') props.setSocio({...props.socio, id:0}) })
    }
    
    const [cuales, setCuales] = useState ([])
    useEffect (() => {
        function cuales() {
            return props.actividades.map(a => {
                return props.socio.actividad.map (e => {
                    return e.actividadId === a.id ? a.nombre : ''
                })
            })
        }
        setCuales(cuales())
    },[props.socio])

    return (<>
        <div className="flex flex-row">
            <img className="max-h-64 ml-5" alt='Imagen socio' src={"https://clubrionegro.ar/back/uploads/" + props.socio.imagen } />
            <div className="flex flex-col">
                <div className="grid grid-cols-2 gap-4 text-lg ml-10">
                    <div className="flex flex-col px-5">
                        <p>Socio</p>
                        <p>{' ' + props.socio.apellido + ' ' + props.socio.nombre}</p>
                        <p>DNI:{' ' + props.socio.documento}</p>
                        <p>Teléfono:{' ' + props.socio.telefono}</p>
                        {cuales.length ?
                            <ul className="text-lg text-red-600">
                                {cuales.map(a => {if (a!=='')
                                        return <li>{a}</li>
                                    }
                               )}   
                            </ul>
                            :
                            ''
                        }
                    </div>
                    <div className="flex flex-col ml-5 px-5">
                        <p>Autorizado</p>
                        <p>{' ' + props.socio.apellido_ref + ' ' + props.socio.nombre_ref}</p>
                        <p>DNI:{' ' + props.socio.documento_ref}</p>
                        <p>Teléfono:{' ' + props.socio.telefono_ref}</p>
                   </div>
                </div>
                <div className="w-full text-center">
                    <button
                        className="h-24 w-32 text -2xl p-3 mt-5 bg-green-300 hover:bg-green-700 hover:text-white border-2 border-green-700 rounded-xl"
                        onClick={(e => registrar())}
                    >
                    Marcar Ingreso
                    </button>
                </div>
            </div>
        </div>
    </>)             
}
