import { useState } from 'react'
import SociosDatos from './socios_datos.js'
import Actividades from './actividades.js'
import Responsable from './responsable.js'
import BuscarSocio from './buscar_socio.js'
import ControlesSocio from './controles_socio.js'
import GrupoFamiliar from './grupo_familiar.js'

export default function Socios(props) {
    
    const [recargar, setRecargar] = useState(0)
    return (<>
    <div className="w-full max-h-1/2">
        {props.socio.id ? (<>
        <div className="flex flex-row w-full h-[33rem]">
            <div className="w-3/5 my-2 border-r-2 border-gray-700">
                <SociosDatos back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio} />
            </div>
            <div className="flex flex-col w-2/5">
                <div className="h-1/3 my-2 ">
                    <Actividades back={props.back} socio={props.socio} setSocio={props.setSocio} token={props.token}/>
                </div>
                <div className="h-1/3 my-2">
                    <GrupoFamiliar back={props.back} socio={props.socio} setSocio={props.setSocio} token={props.token}/>
                </div>
                <div className="h-1/3 my-2">
                    <Responsable back={props.back} token={props.token} socio={props.socio} setSocio={props.setSocio}/>
                </div>
            </div>
        </div>
        {props.socio.id ?
            <ControlesSocio token={props.token} back={props.back} socio={props.socio} setSocio={props.setSocio} setRecargar={setRecargar}/>
        : '' }
        </>)
        : 
        <div className="p-2">
            <BuscarSocio back={props.back} token={props.token} setSocio={props.setSocio} setRecargar={setRecargar} recargar={recargar}/>
        </div>
        }
    </div>
        
    </>)
}