import { useState, useEffect } from 'react'

export default function BuscarSocio(props) {
    const [socios, setSocios] = useState([])
    
    const [apellido, setApellido] = useState('')
    const [nombre, setNombre] = useState('')
    const [dni, setDni] = useState('')

    function documento (e) {
        if (( /^[0-9]+$/.test(e) && e.length<9) || e=== '')
            setDni(e)
    }

    useEffect(() => {
    function traer_socios() {
        fetch(props.back + 'admin/traer_socios_general', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + props.token,
            'Content-Type': 'application/json' 
          }
        })
        .then (response => response.json())
        .then (json => setSocios(json))
      }
      traer_socios()
      props.setRecargar(0)
    }, [props.token, props.back, props.recargar])
    return (<>
        <div className="grid grid-cols-3 tex-lg justify-items-center py-1">
            <div>
                Apellido
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}
                />
            </div>
            <div>
                Nombre
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                />
            </div>
            <div>
                Documento
                <input 
                    className="w-24 p-1 ml-5"
                    type="text"
                    value={dni}
                    onChange={e => documento(e.target.value)}
                />
            </div>
        </div>
        <ul className="overflow-y-auto cursor-pointer h-[13rem]">
        {!(nombre==='' && apellido==='' && dni==='') ?
        socios.map(a => {
           if (a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.documento.includes((dni))) 
                return  (<>
                <li 
                    key={a.id} 
                    className="grid grid-cols-3 px-2 text-sm"
                    onClick={() => props.setSocio(a)}
                 >
                <div>{a.apellido}</div>
                <div>{a.nombre}</div>
                <div>{a.documento}</div>
                 </li>
                 </>)
        })
        :''
        }
        </ul>
    </>)
}