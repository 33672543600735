export default function ControlesSocio (props) {

    function guardar () {
        var a = props.socio
        a = {...a, nacimiento: new Date(a.nacimiento), alta_sistema: new Date(a.alta_sistema)}
        fetch(props.back + 'admin/update', {
            body: JSON.stringify(a),
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + props.token,
              'Content-Type': 'application/json' 
            }
        })
        .then (response => response.json())
        .then (json => {
            props.setSocio({id: 0})
            props.setRecargar(1)
        })
    }

    return (<>
    <div className="flex flex-row justify-center">
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-green-200 hover:bg-green-800 hover:text-white"
            onClick={e => guardar()}
        >
            Guardar Cambios
        </button>
        <button
            className="my-2 p-2 mx-10 rounded-xl bg-red-200 hover:bg-red-800 hover:text-white"
            onClick={e => props.setSocio({id:0})}
        >
             Descartar Cambios
        </button>
    </div>
    </>)
}