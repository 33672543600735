import {useState,  useEffect} from 'react'

export default function Actividades(props) {
    const [actividad, setActividad] = useState([])
    
    useEffect (() => {
        function traer_actividades () {
            fetch (props.back + 'admin/traer_actividades', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
                })
                .then (response => response.json())
                .then (json => setActividad(json))
        }
    traer_actividades()
    const t = props.socio.actividad.map (a => {return a.actividadId})
    if (t.length)
        props.setSocio({...props.socio, elegido: t})
    else
        props.setSocio({...props.socio, elegido: []})
    },[props.back, props.token])
    

    function generar_lista() {
        const l = props.socio.elegido.map (a => {
            const nombre = actividad.filter(e => e.id === a)
            if (nombre.length) {
            return (<>
                <div className="flex flex-row justify-between px-2">
                        <li>{nombre[0].nombre}</li>
                        <label 
                            className="text-lg text-red-600"
                            onClick={() => borrar_actividad(a)}
                            >X
                        </label>
                        </div>
                </>)
            }
            else return ''
        })
        return l
    }

    function agregar_actividad(cual) {
        if (! props.socio.elegido.filter(a => a === cual).length)  {
            props.setSocio({...props.socio, elegido: props.socio.elegido.concat(cual)})
        }
    }

    function borrar_actividad(cual) {
        const t = props.socio.elegido.filter(a => a !== cual)
        props.setSocio({...props.socio, elegido: t})
    }

    return (<>
    <label className="px-2 text-lg text-center w-full font-semibold pl-2 mb-2">Actividades</label>
        <div className="grid grid-cols-2 gap-2">      
        <div className="cursor-pointer overflow-y-auto h-1/6">
            <ul className="px-2 GrupoFamiliar">
            {actividad.map(a => {
                return <li key={a.id} onClick={() => agregar_actividad(a.id)}>{a.nombre}</li>
            })}    
            </ul>    
        </div>
        {props.socio.hasOwnProperty('elegido') && props.socio.elegido.length ?
        <div className="px-2">
            <ul>
            {generar_lista()}
            </ul>
            </div>
        
        : '' }
        </div>
    </>)
}